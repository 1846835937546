<template>
  <div class="content-wrapper pad">
    <section class="stories-list">
      <loadMore
        :has-primary-button="false"
        :endpoint="storiesEndpoint"
        :item-class-func="storyItemAdditionalClasses"
        width="column is-flex-tablet is-column"
      >
        <template slot="item" slot-scope="props">
          <story-card
            :story="props.item"
            :newsroom-prop="newsroom"
            :show-pinned-posts="true"
            :show-less-info="true"
            :use-custom-color="true"
            story-path-name="embedded.story"
            newsroom-path-name="embedded.newsroom.stories"
            class="border-grey-light"
          />
        </template>
      </loadMore>
    </section>
  </div>
</template>

<script>
import StoryCard from '@/components/storySearch/StoryCard.vue'

export default {
  components: {
    StoryCard
  },

  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      storiesEndpoint: `stories?newsrooms[]=${this.newsroom.id}&sort[]=newest`,
      pageName: 'Stories'
    }
  },

  methods: {
    storyItemAdditionalClasses (story) {
      return story.is_pinned
        ? 'is-full-tablet is-full-desktop'
        : 'is-half-tablet is-one-third-desktop'
    }
  }
}
</script>
